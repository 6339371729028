<template>
  <form @submit.prevent="submit" v-if="$store.getters.isSuper">
    <div class="p-mb-3 p-input-filled">
      <div class="p-field" v-for="field in fields" :key="field.name">
        <InputText
          :placeholder="`${field.label}`"
          type="text"
          v-model="formData[field.name]"
          @blur="validation.formData[field.name].$touch()"
          :class="{ 'p-invalid': validation.formData[field.name].$error }"
        />
        <BaseValidationErrors
          :field="validation.formData[field.name]"
          :rules="['required']"
        />
      </div>
    </div>
    <Button
      type="submit"
      class="submit"
      :disabled="disabled || validation.$invalid"
      :label="label"
    />
  </form>
</template>

<script lang="ts">
import { ref, watchEffect, defineComponent } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'
import { roles } from '@/helpers/constants.ts'
import { Location } from '@/types.ts'

export default defineComponent({
  components: { Button, InputText, BaseValidationErrors },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Save Location'
    },
    location: {
      type: Object as () => Location,
      default: () => {
        return {}
      }
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      address: '',
      city: '',
      name: '',
      postalCode: '',
      province: ''
    })

    const rules = {
      formData: {
        address: { required },
        city: { required },
        name: { required },
        postalCode: { required },
        province: { required }
      }
    }

    const fields = [
      { name: 'name', label: 'Name' },
      { name: 'address', label: 'Address' },
      { name: 'city', label: 'City' },
      { name: 'province', label: 'Province' },
      { name: 'postalCode', label: 'Postal Code' }
    ]

    const validation = useVuelidate(rules, { formData })

    watchEffect(() => {
      if (Object.keys(props.location).length) {
        const { address, city, name, postalCode, province } = props.location
        formData.value = { address, city, name, postalCode, province }
      }
    })

    function submit() {
      validation.value.$touch()
      if (!validation.value.$invalid) emit('submitted', formData.value)
    }

    return { formData, roles, validation, submit, fields }
  }
})
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
</style>
